<template>
  <message-send
    v-if="isSend"
    :medias="messageProps.mediasUrl"
    v-bind="messageProps"
  />
  <message-received
    v-else
    :medias="messageProps.mediasUrl"
    v-bind="messageProps"
  />
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import MessageReceived from "./MessageReceived.vue";
import MessageSend from "./MessageSend.vue";
import { MESSAGE_SEND, MESSAGE_RECEIVED } from "./mesageTypes";
import { mapState } from "vuex";

export default Vue.extend({
  components: { MessageSend, MessageReceived },
  name: "chat-message",
  props: {
    messageProps: Object,
  },
  data() {
    return {
      messageTypes: {
        MESSAGE_SEND,
        MESSAGE_RECEIVED,
      },
    };
  },
  computed: {
    ...mapState(["profile"]),
    ...mapState("crmSMSModule", ["contactSelect"]),

    isSend() {
      if (this.messageProps.to == this.contactSelect.number) {
        return true;
      }
      return false;
    },
  },
});
</script>
